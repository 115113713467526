body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
	background-color: #000000;
}

.section
{
  width: 100%!important;
  height: 100vh!important;
}

body::-webkit-scrollbar
{
	width: 6px;
	background-color: #000000;
}

body::-webkit-scrollbar-thumb
{
	background-color: #ffffff;
}

.App {
  text-align: center;
}

body
{
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}